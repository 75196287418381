<template>
  <div id="product">
    <!--头部导航-->
    <div style="z-index:9999;position:fixed;top:0;left: 0;">
      <baseNav></baseNav>
    </div>
    <!--内容-->
    <div class="productCon">
      <!--内容头部-->
      <div class="commenHead">
        <div class="about_tit">
          <p v-if="productData.title&&productData.title.length>0"
             class="animated bounceInLeft  about_tit_one"
             v-html="productData.title[0].content"
              style="font-size:6.944vw;line-height:8.472vw"
          >
          </p>
        </div>
        <div class="about_img">
          <div class="right_grid ">
            <img src="../assets/images/pic_grid_r.png" alt="">
          </div>
          <div class="left_grid " style="height: auto;">
            <img src="../assets/images/pic_grid_l.png" alt="">
          </div>
        </div>
      </div>

<!--      内容盒子-->
      <div class="productBox">
        <p class="centerTitle">Product Center</p>
        <ul class="productCon">
          <li class="item" v-for="(item,index) in productList1" :key="item.id">
            <div class="item_l">
              <img :src="item.thumb" alt="">
            </div>
            <div class="item_r">
              <h3 v-html="item.title">
              </h3>
              <p class="tit" v-html="item.subtitle"></p>
              <p class="con" v-html="item.desc"></p>
              <v-touch @press="press(index)" @pressup="pressup(item.id,index)" @tap="tap(item.id,index)">
                <div class="btn" :class="{press_btn:isPress==index}">
                  Read More <span></span>
                </div>
              </v-touch>


            </div>
          </li>
        </ul>

      </div>
      <div v-show="Show1" style="margin-top: 19.444vw;">
        <Copyright></Copyright>
      </div>
      <div v-show="!Show1" style="width: 100%;position: fixed;bottom: 0; left: 50%;transform: translateX(-50%)">
        <Copyright></Copyright>
      </div>
    </div>
  </div>

</template>

<script>
import baseNav from "./baseNav";
import Copyright from "@/component/Copyright";
import Swiper from "swiper";
import {GetProductData1,GetIndexDoc} from "@/api/api";
export default {
  data(){
    return{
      //标题+内容 //获取产品中心banner数据
      productData:{},
      //列表1
      productList1:[],
      //copyright展示
      Show1:false,
      isPress:9999,

    }
  },
  mounted() {
    //获取产品中心banner数据
    this.toGetIndexDoc(5)
    this.toGetProductData1()

  },
  methods:{
    //获取产品中心banner数据
    toGetIndexDoc(data){
      let _this=this
      GetIndexDoc(data)
          .then(r=>{
            let res=r.data
            if(res.status!=1){
              _this.productData=[]
              return
            }
            let apiIndex=res.data
            _this.productData=apiIndex
          })
    },
    //获得列表1
    toGetProductData1(){
      let _this=this
      GetProductData1()
      .then(r=>{
        let res=r.data
        if(res.status!=1){
          _this.productList1=[]
          _this.Show1=false
          return
        }
        console.log('------------')
        console.log(res.data)
        _this.productList1=res.data
        _this.Show1=true
        //发展历程目录
        new Swiper('.productBox .timeLineP1 .timeLineCon .mySwiper', {
          slidesPerView: 'auto',
          observer: true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents: true,//修改swiper的父元素时，自动初始化swiper
        })
      })
    },
    //点击
    press(n){
        this.isPress=n
    },
    pressup(id){
      this.$router.push('/index/product/detail.html?id='+id)
    },
    tap(id,n){
      let _this=this
      this.isPress=n
      setTimeout(function (){
        _this.$router.push('/index/product/detail.html?id='+id)
      },100)
    },
  },
  components:{
    baseNav,
    Copyright
  }

}
</script>
<style lang="less" scoped>
#product{
  padding-top: 13.888vw;
  background-color: #071D42;
  min-height: calc(100vh - 13.888vw);
  user-select:none;
}
.productCon{
  .commenHead{
    background:url("../assets/images/pic_banner_product.png") no-repeat 70% top;
    background-size: 156%;
    .about_tit_text p{
      margin-top: 0;
      margin-bottom:2.2222vw;
      animation-delay: 0s;
    }
    .about_tit p{
      animation-delay: 0.1s;
    }

  }
  .productBox{
    padding-bottom:5.555vw;
    margin:0 5.555vw 0 5.555vw;
      .centerTitle{
        font-size: 4.444vw;
        font-family: Montserrat-Medium, Montserrat;
        font-weight: 500;
        color: #20BFD0;
        line-height: 5.416vw;
        margin-bottom: 3.333vw;
      }
    .productCon{
      .item{
        padding:3.333vw 0 2.222vw 0;
        border-top: 0.138vw solid rgba(32, 191, 208, 0.5);
        .item_l{
          display: inline-block;
          width: 27.777vw;
          height: 27.777vw;
          // border: 0.138vw dashed #5e5e5e;
          margin-right: 2.222vw;
          vertical-align: top;
          img{
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }
        .item_r{
          display: inline-block;
          width:58vw;
          h3{
            font-size: 3.888vw;
            font-family: Montserrat-Medium, Montserrat;
            font-weight: 500;
            color: #20BFD0;
            line-height: 4.722vw;
            margin-bottom: 2.916vw;
  
          }
          .tit{
            font-size: 3.194vw;
            font-family: Montserrat-Regular, Montserrat;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 3.888vw;
            margin-bottom: 0.833vw;
          }
          .con{
            // font-size: 2.361vw;
            font-size: 2.777vw;
            // font-family: Montserrat-Thin, Montserrat;
            font-weight: 300;
            color: #FFFFFF;
            line-height: 3.888vw;
            margin-bottom: 1.944vw;
          }
          .btn{
            font-size: 2.222vw;
            font-family: Montserrat-Regular, Montserrat;
            font-weight: 400;
            width:21.805vw;
            height: 5.833vw;
            border:0.138vw solid #20BFD0;
            color:#20BFD0;
            text-align: center;
            line-height: 5.833vw;
            border-radius: 0.6vw;
            span{
              display: inline-block;
              margin-left: 1vw;
              width:5.555vw;
              height: 2.777vw;
              width:4vw;
              height: 1.5vw;
              background: url("../assets/images/ico_arrow_pixel2.png") no-repeat right center;
              background-size: 100%;

            }
          }
          .press_btn{
            background: rgba(32, 191, 208, 1);
            color:rgba(7, 29, 66, 1);
            span{
              background: url("../assets/images/ico_arrow_pixel2_p.png") no-repeat right center;
              background-size: 100%;
            }
          }
        }
      }
    }



  }
}

</style>